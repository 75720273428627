.heroSection {
  margin: 6.42rem auto 10rem;
  text-align: center;
}

.heroTitle {
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-size: 8rem;
  font-weight: 1000;
  width: 85%;
  margin: 0.3rem auto;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: -0.2px;
}

.titleDiv1 {
  text-align: left;
}

.heroText {
  width: 65%;
  margin: 1rem auto;
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: Inter, sans-serif;
  color: #2d3e51;
  font-weight: 500;
}

#Layer_1 {
  width: 6rem;
  margin: 1.6rem 0;
  height: auto;
}

@keyframes upDown {
  from {
    transform: translateY(0.8rem);
  }

  to {
    transform: translateY(0);
  }
}

@media screen and (max-width: 1024px) {
  .heroTitle {
    font-size: 3.8rem;
    width: 70%;
    margin: 0.3rem auto;
  }

  @media screen and (max-width: 800px) {
    .heroTitle {
      font-size: 2.8rem;
      width: 80%;
    }

    .heroText {
      font-size: 1.3rem;
    }

    #Layer_1 {
      width: 4rem;
    }

    @media screen and (max-width: 600px) {
      .heroSection {
        margin: 4.6rem auto 6.3rem;
        text-align: center;
      }

      .heroTitle {
        font-size: 3.8rem;
        width: 90%;
        margin: 0.3rem auto;
        text-align: center;
      }

      .titleDiv1 {
        text-align: center;
      }

      .heroText {
        width: 90%;
        font-size: 1rem;
        line-height: 1.3;
        font-weight: 400;
        margin: 0.4rem auto;
      }

      #Layer_1 {
        width: 3.42rem;
        margin: 1.2rem 0;
      }
    }
  }
}
