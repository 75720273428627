.contact {
  margin: 1rem auto 6.42rem;
  width: 70%;
  text-align: center;
}

.contact h3 {
  font-size: 4.5rem;
  font-family: "Yanone Kaffeesatz", sans-serif;
  text-transform: uppercase;
  letter-spacing: .12px;
}

.contactText {
  font-size: 1.42rem;
  font-family: Inter, sans-serif;
  margin: .5rem auto;
  color: #2d3e51;
}

.contactText span {
  font-style: italic;
  color: #e67f22;
}

.SMIcons {
  font-size: 2rem;
  cursor: pointer;
  color: #2d3e51;
}

.SMDiv {
  margin-top: 5rem;
}

.linkIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.copy {
  font-size: 1rem;
  font-family: Inter, sans-serif;
  font-weight: 400;
  margin: .8rem 0;
}


@media screen and (max-width: 1024px) {
  .contact h3 {
    font-size: 3.42rem;
  }

  @media screen and (max-width: 800px) {
    .SMDiv h6 {
      font-size: 2rem;
    }

    @media screen and (max-width: 600px) {
      .contact {
        width: 90%;
      }

      .contact h3 {
        font-size: 2.42rem;
      }

      .contactText {
        font-size: 1rem;
      }

      .SMDiv h6 {
        font-size: 1.5rem;
      }
    }
  }
}