.Header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 13rem;
}

.navLinks {
  display: flex;
  align-items: center;
  list-style: none;
  font-size: 1.6rem;
  gap: 1rem;
  font-family: Inter, sans-serif;
  font-weight: 600;
  cursor: pointer;
}

.resume {
  font-family: Inter, sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  padding: .5rem 1rem;
  border-radius: 1rem;
  text-align: center;
  border: transparent;
  color: black;
  background-color: #F8FFFE;
  cursor: pointer;
}

.navLinksDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F6D59F;
  gap: 3rem;
  padding: .6rem 1.2rem;
  border-radius: 1.2rem;
  z-index: 5;
  position: fixed;
  margin-bottom: 1.3rem;
  bottom: 0;
}

.upBtn {
  font-size: 2.42rem;
  background-color: #F8FFFE;
  padding: .5rem .5rem;
  border-radius: .8rem;
  font-weight: 700;
  cursor: pointer;
}


@media screen and (max-width: 1024px) {
  @media screen and (max-width: 800px) {
    @media screen and (max-width: 600px) {
      .logo {
        width: 8rem;
      }

      .navLinks {
        font-size: 1.2rem;
        gap: .8rem;
        font-weight: 500;
      }

      .resume {
        font-size: 1.3rem;
        font-weight: 600;
        border-radius: .6rem;
      }

      .navLinksDiv {
        gap: 1.5rem;
        border-radius: .8rem;
        margin-bottom: 1rem;
      }
    }
  }
}