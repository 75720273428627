.aboutSection {
  width: 95%;
  margin: 1rem auto;
  text-align: center;
  position: relative;
}

._me_container {
  max-width: 70%;
  margin: 1rem auto;
  border-radius: 0.8rem;
}

._me {
  width: 80%;
  border-radius: 0.8rem;
}

.aboutText {
  font-size: 1.6rem;
  font-family: Inter, sans-serif;
  font-weight: 400;
  margin: 2rem auto;
  width: 85%;
  line-height: 1.4;
  color: #2d3e51;
}

.aboutText span {
  font-weight: bold;
}

.skillsDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: left;
  margin: 3rem auto;
}

.skillsTitle span {
  font-style: italic;
  font-weight: 600;
}

.skillsWrapper {
  margin: 1rem auto;
  width: 90%;
  background-color: #383838;
  padding: 0.8rem 2.5rem;
  border-radius: 1.8rem;
}

.skillsTitle {
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-size: 3.6rem;
  color: #fb9f3b;
}

.skillsLogos {
  display: flex;
  gap: 1.3rem;
  flex-wrap: wrap;
  margin: 1.6rem auto;
  width: 80%;
  align-items: center;
  justify-content: center;
}

.skillsLogos img {
  width: 4.8rem;
  padding: 0.3rem 0.6rem;
  border-radius: 0.6rem;
  background-color: #f7f7f7;
  aspect-ratio: 3/3;
  object-fit: contain;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

._stack_section {
  text-align: center;
  margin: 6rem auto;
}

._tech_stack {
  font-size: 5rem;
  font-family: "Yanone Kaffeesatz", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.12px;
}

@media screen and (max-width: 1024px) {
  @media screen and (max-width: 800px) {
    .aboutText {
      font-size: 1.3rem;
      width: 90%;
      line-height: 1.2;
    }

    .skillsTitle {
      font-size: 2.6rem;
    }

     ._tech_stack {
        font-size: 3.5rem;
      }

    @media screen and (max-width: 600px) {
      .aboutText {
        font-size: 1rem;
        width: 99%;
        line-height: 1.2;
      }

      .aboutText span {
        font-weight: 500;
      }

      ._me_container {
        max-width: 100%;
      }

      .skillsTitle {
        font-size: 1.8rem;
      }

      .skillsLogos {
        align-items: center;
        justify-content: center;
      }

      .skillsLogos img {
        width: 4rem;
      }

      .skillsWrapper {
        margin: .8rem auto;
        width: 98%;
        padding: 0.8rem;
      }

      .skillsDiv {
        text-align: center;
        margin: 1rem auto 3rem;
      }

      ._me {
        width: 100%;
        border-radius: 0.8rem;
      }

      ._tech_stack {
        font-size: 2.5rem;
      }
    }
  }
}
