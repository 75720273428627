._work_section {
  margin: 13.5rem auto;
  text-align: center;
}

._work_title {
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-size: 5rem;
  text-transform: uppercase;
  margin: 1rem auto;
  letter-spacing: 0.2px;
}

._project_logo {
  width: 10rem;
}

._extrac {
  width: 15rem;
}

._work_container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  gap: 3rem;
  margin: 2.6rem auto;
}

._project_container {
  background-color: #fff;
  border-radius: 1rem;
  padding: 3rem 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.8rem;
  text-align: center;
  flex: 1;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

._project_container p {
  font-size: 1.2rem;
  line-height: 1.4;
  font-family: "Inter Tight", sans-serif;
  color: #2d3e51;
}

._project_container button,
#_discontinued {
  background-color: #ebf5ff;
  border-radius: 0.5rem;
  border: transparent;
  padding: 0.6rem 1rem;
  letter-spacing: 0.15px;
}

._project_container button a,
#_discontinued {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
  font-size: 1.16rem;
  color: #0268d1;
}

#_discontinued {
  background-color: hsla(0, 48%, 88%, 0.693);
  color: red;
}

@media screen and (max-width: 1024px) {
  ._work_section {
    width: 95%;
    gap: 1rem;
  }

  ._work_container {
    gap: 1rem;
    margin: 1.6rem auto;
  }

  ._project_container {
    padding: 1.2rem 1rem;
  }

  @media screen and (max-width: 800px) {
    ._work_container {
      gap: 1.8rem;
      flex-direction: column;
      margin: 0 auto;
    }

    ._work_section {
      margin: 6.5rem auto;
      width: 75%;
    }

    ._work_title {
      font-size: 3.5rem;
    }

    @media screen and (max-width: 600px) {
      ._work_title {
        font-size: 2.8rem;
        width: 65%;
      }

      ._work_section {
        width: 95%;
      }

      ._project_container {
        padding: 2rem 1.8rem;
      }

      ._project_container p {
        font-size: 1rem;
        line-height: 1.3;
      }
    }
  }
}
